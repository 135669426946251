import {css} from '@emotion/core';
import {Children, useMemo} from 'react';
import {focusTransitionDuration} from '../../styles/animations';
import {colors} from '../../styles/colors';
import {commonBoxShadow} from '../../styles/css';
import {rhythm0_5, rhythm1} from '../../styles/typography';

const galleryCss = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: rhythm0_5,
  // background: '#333',
  padding: rhythm0_5,
  borderRadius: '2px',
  marginBottom: rhythm1,
  background: colors.backgrounds.light,

  '& a': {
    display: 'inline-block',
    borderRadius: '5px',
    overflow: 'hidden',

    boxShadow: commonBoxShadow,
    // borderRadius: '2px',

    // '& > *': {
      transition: `
        transform ${focusTransitionDuration}ms ease-out,
        filter ${focusTransitionDuration}ms ease-out
      `,
    // },

    [`
      &:hover,
      &:active,
      &:focus
    `]: {
      background: 'none !important',
      // filter: 'brightness(1.2)',

      // boxShadow: `0 0 0 2px ${colors.links.hover.background}`,

      // '& > *': {
        transform: 'scale(0.98)',
        filter: 'brightness(1.1)',
      // },

      // '&::after': {
      //   opacity: 1,
      // },
    },
  },
});

const galleryItemCss = css({
  // boxShadow: '0 0 5px #666666',
  // padding: rhythm0_125,
});

type Props = {
  children: React.ReactNode,
};

export const Gallery = (props: Props) => {
  const {children} = props;

  const galleryItems = useMemo(
    () => {
      return Children.toArray(children).map((child, index) => {
        return (
          <div css={galleryItemCss} key={index}>
            {child}
          </div>
        );
      });
    },
    [children],
  );

  return (
    <div css={galleryCss}>
      {galleryItems}
    </div>
  );
};
